<template>
  <div id="preview">
    <div class="inner">
      <div class="image fit">
        <img src="images/dig-me-a-well.jpg" alt="Apocalypse by Shutterwax" />
      </div>
      <div class="content">
        <header>
          <h2>Apocalypse, Please Be True</h2>
        </header>
        <iframe style="border: 0; width: 100%; height: 42px;" src="https://bandcamp.com/EmbeddedPlayer/album=1039235431/size=small/bgcol=ffffff/linkcol=0687f5/track=3470369351/transparent=true/" seamless><a href="https://shutterwax.bandcamp.com/album/dig-me-a-well">Dig Me a Well by Shutterwax</a></iframe>
        <p>Sat back waiting for the world to end, it was on TV so I knew that it would happen...</p>
      </div>
    </div>
    <router-link :to="{ name: 'Jessica', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Time', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BigNewsPage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
